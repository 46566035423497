import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import axios from 'axios';
import { useLocation } from "react-router-dom";
import axios from "../utils/axios";
export const showMessage = (msg, type) => {
  if (type === "success") {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (type === "error") {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.info(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
export const showAsModel = (msg, type) => {
  if (type === "success") {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      hideProgressBar: false,
    });
  } else if (type === "error") {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      hideProgressBar: false,
    });
  } else {
    toast.info(msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      hideProgressBar: false,
    });
  }
};

export const callAPI = (method, endpoint, data) => {
  let errors;
  const token = sessionStorage.getItem("memberAuth");

  // // Only set the Authorization header if the token is present
  // if (token) {
  //   axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  // }

  let tkn = sessionStorage.getItem("memberAuth")
    ? sessionStorage.getItem("memberAuth")
    : sessionStorage.getItem("access_token");
    
  axios.defaults.headers.common["Authorization"] = "Bearer " + tkn;
  axios.defaults.headers.common['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
  //axios.defaults.headers.common['Timezone-Offset'] = new Date().getTimezoneOffset();
  if (method === "post") {
    return axios
      .post(endpoint, data)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
  if (method === "get") {
    return axios
      .get(endpoint)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          if (res.data.hasOwnProperty("pagination")) {
            let res_data = {
              data: res.data.data,
              pagination: res.data.pagination,
              alphabet:res.data.alphabets
            };
            return res_data;
          } else {
            return res.data.data;
          }
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
        //
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          if(Object.keys(err.response.data.data).length !== 0){
            Object.keys(errors).map(function (key, index) {
              showMessage(`${errors[key]}`, "error");
            });
          }
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          //window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = "/";
        }
      });
  }
  if (method === "delete") {
    return axios
      .delete(endpoint, data)
      .then((res) => {
        if (res.data.status) {
          if (res.data.message !== "") {
            showMessage(`${res.data.message}`, "success");
          }
          return res.data.data;
        } else {
          errors = res.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          errors = err.response.data.errors;
          Object.keys(errors).map(function (key, index) {
            showMessage(`${errors[key]}`, "error");
          });
        } else if (err.response.status === 401) {
          showMessage("Access denied.", "error");
          window.location.href = "/";
        } else if (err.response.status === 404) {
          showMessage("API request not found", "error");
        } else {
          showMessage(err.message, "error");
          //window.location.href = '/';
        }
      });
  }
};
export const isMemberAuthenticated = () => {
  let token = sessionStorage.getItem("memberAuth");

  if (!token || token === "") {
    return false;
  } else {
    return true;
  }
};
export const isAdminOrUser = () => {
  let type = sessionStorage.getItem("memberAuth") ? "user" : "admin";
  return type;
};
export default function GetQueryString(props) {
  const search = useLocation().search;
  const value = new URLSearchParams(search).get(props);
  return value;
}
